import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useOffset from 'hooks/useOffset';
import establishOrganizationTeamsThunk, {
    loadMoreOrganizationTeamsThunk,
    resetOrganizationTeamsThunk,
} from 'redux/actions/thunks/organizationTeams';

import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { useAuth0 } from '@auth0/auth0-react';
import {
    establishOrganizationAssignSmartTipAssignedTeamsThunk,
    resetUsersAndTeamsListsThunk,
} from 'redux/actions/thunks/organizationAssignSmartTip';

const INFINITE_SCROLL_THRESHOLD = 20;

const TeamsSection = ({ onUpdate, smartTipId }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [searchFilter, setSearchFilter] = useState('');
    const dispatch = useDispatch();

    const [offset, incrementOffset, resetOffset] = useOffset(
        INFINITE_SCROLL_THRESHOLD
    );

    const { organizationTeams, hasMoreOrganizationTeams } = useSelector(
        (state) => state.organizationTeams
    );

    const { assignedTeams } = useSelector(
        (state) => state.organizationAssignSmartTip
    );

    const resetItemsList = () => {
        resetOffset();
        dispatch(resetOrganizationTeamsThunk());
    };

    const loadMore = () => {
        dispatch(
            loadMoreOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                offset,
                {
                    search: searchFilter,
                }
            )
        );
        incrementOffset();
    };

    useEffect(() => {
        dispatch(
            establishOrganizationTeamsThunk(
                getAccessTokenSilently,
                logout,
                INFINITE_SCROLL_THRESHOLD,
                0,
                {
                    search: searchFilter,
                }
            )
        );
        incrementOffset();
    }, [searchFilter]);

    useEffect(() => {
        dispatch(
            establishOrganizationAssignSmartTipAssignedTeamsThunk(
                getAccessTokenSilently,
                logout,
                smartTipId
            )
        );

        return () => dispatch(resetUsersAndTeamsListsThunk());
    }, []);

    return (
      <DropdownSelect
            type="team"
            items={organizationTeams}
            loadMore={loadMore}
            hasMore={hasMoreOrganizationTeams}
            onSearch={setSearchFilter}
            resetItemsList={resetItemsList}
            preselectedItems={assignedTeams}
            handleSetSelectedItems={(values) =>
                onUpdate({ team_ids: [...values] })}
            placeholder="Select teams"
            uncheckAssignToAllUsers={() => {}}
        />
    );
};

TeamsSection.propTypes = {
    smartTipId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default TeamsSection;
