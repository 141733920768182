import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { UsersSection } from 'containers/AssignSmartTipModal/UsersSection';
import TeamsSection from 'containers/AssignSmartTipModal/TeamsSection';
import Checkbox from 'components/Checkbox';
import { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { choosenFilterTags } from 'redux/actions/organizationUsers';
import {
    ModalBody,
    SectionButtons,
    InputSection,
    Title,
    Row,
} from './AssignSmartTipModal.style';
import { ClassificationTagDropdown } from '../ClassificationTagDropdown/ClassificationTagDropdown';

const AssignSmartTipModal = ({
    toggleModal,
    onUpdate,
    smartTipId,
    handleAssign,
    assignToAll,
    toggleAssignToAll,
    isUsersSection,
    toggleSection,
    setAssignToAll,
    unassignedUserIds,
    setUnassignedUserIds,
}) => {
    const [selectedUser, setSelectedUser] = useState(false);
    const [classificationTags, setClassificationTags] = useState({
        role_job: [],
        department: [],
    });

    const dispatch = useDispatch();

    const { amountOfAllUsers } = useSelector(
        (state) => state.organizationUsers
    );

    const selectedTagsList = useRef(null);

    const mapFiltersTagsList = () => {
        const tags = [];
        Object.keys(classificationTags).forEach((key) => {
            classificationTags[key].forEach((tag) => {
                tags.push(tag.id);
            });
        });
        dispatch(choosenFilterTags(tags));
    };

    useEffect(() => {
        mapFiltersTagsList();
    }, [classificationTags]);

    return (
        <Modal
            headerText="Assign Smart Tip"
            customStyles={css`
                width: 670px;
            `}
            closeModal={toggleModal}
            modalFooter={() => (
                <>
                    <Button
                        primary
                        onClick={toggleModal}
                        style={{ marginRight: '16px' }}
                    >
                        Cancel
                    </Button>
                    <Button primaryDark onClick={handleAssign}>
                        Save
                    </Button>
                </>
            )}
        >
            <ModalBody>
                <SectionButtons>
                    <Button
                        primary={isUsersSection}
                        transparent={!isUsersSection}
                        borderless
                        onClick={() => toggleSection(true)}
                    >
                        Users
                    </Button>
                    <Button
                        primary={!isUsersSection}
                        transparent={isUsersSection}
                        borderless
                        onClick={() => toggleSection(false)}
                    >
                        Teams
                    </Button>
                </SectionButtons>

                <InputSection shadow borderTop bottomBorderRadius>
                    <Title noCentered>Filters</Title>
                    <ClassificationTagDropdown
                        currentlySavedTags={classificationTags}
                        setClassificationTags={setClassificationTags}
                        layout="vertical"
                        teleportChipsLocRef={selectedTagsList}
                        localMode
                    />

                    {isUsersSection && (
                        <>
                            <Title>Assign to all</Title>
                            <Row
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    disabled={selectedUser && !assignToAll}
                                    toggleSelected={toggleAssignToAll}
                                    isSelected={assignToAll}
                                />
                                <Title dark>
                                    Assign to all filtered or unfiltered users{' '}
                                    {assignToAll &&
                                        `(${amountOfAllUsers} users)`}
                                </Title>
                            </Row>
                        </>
                    )}
                </InputSection>

                <div
                    style={{ padding: '0px 24px' }}
                    ref={(el) => {
                        selectedTagsList.current = el;
                    }}
                />

                {isUsersSection ? (
                    <InputSection borderRadius>
                        <Title noCentered>Assign to Users</Title>
                        <div>
                            <UsersSection
                                smartTipId={smartTipId}
                                onUpdate={onUpdate}
                                classificationTags={classificationTags}
                                assignToAllUsers={assignToAll}
                                setAssignToAll={setAssignToAll}
                                setSelectedUser={setSelectedUser}
                                unassignedUserIds={unassignedUserIds}
                                setUnassignedUserIds={setUnassignedUserIds}
                            />
                        </div>
                    </InputSection>
                ) : (
                    <InputSection borderTop bottomBorderRadius>
                        <Title noCentered>Assign to teams</Title>
                        <div>
                            <TeamsSection
                                smartTipId={smartTipId}
                                onUpdate={onUpdate}
                            />
                        </div>
                    </InputSection>
                )}
            </ModalBody>
        </Modal>
    );
};

AssignSmartTipModal.propTypes = {
    smartTipId: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    handleAssign: PropTypes.func.isRequired,
    assignToAll: PropTypes.bool.isRequired,
    toggleAssignToAll: PropTypes.func.isRequired,
    isUsersSection: PropTypes.bool.isRequired,
    toggleSection: PropTypes.func.isRequired,
    setAssignToAll: PropTypes.func.isRequired,
    unassignedUserIds: PropTypes.array.isRequired,
    setUnassignedUserIds: PropTypes.func.isRequired,
};

AssignSmartTipModal.defaultProps = {};

export default AssignSmartTipModal;
