import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { assignToAllUsersAction } from 'redux/actions/smartTipDetails';
import { resetOrganizationSmartTipDetailsThunk } from 'redux/actions/thunks/organizationSmartTipDetails';
import { updateOrganizationSmartTipThunk } from 'redux/actions/thunks/organizationSmartTips';
import AssignSmartTipModalComponent from 'components/AssignSmartTipModal';
import { useAuth0 } from '@auth0/auth0-react';

const AssignSmartTipModal = ({ smartTipId, toggleModal, resetSmartTips }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();

    const [assignToAll, setAssignToAll] = useState(false);
    const [isUsersSection, toggleSection] = useState(true); // Open modal at Users isUsersSection by default
    const [values, setValues] = useState(undefined);

    const [unassignedUserIds, setUnassignedUserIds] = useState([]);

    // const { assignedTeams } = useSelector(
    //     (state) => state.organizationAssignSmartTip
    // );

    const { filterTags, amountOfAllUsers } = useSelector(
        (state) => state.organizationUsers
    );

    const { assignedUsers, assignedTeams } = useSelector(
        (state) => state.organizationAssignSmartTip
    );

    const onUpdate = (newValues) => {
        setValues(newValues);
    };

    const handleAssign = () => {
        const unassignedUser = [];

        assignedUsers.forEach((item) => {
            if (!values.user_ids.includes(item.id)) {
                unassignedUser.push(item.id);
            }
        });
        const assignedUsersList = [];
        // const unassignedUsersList = [];

        if (values.user_ids) {
            values.user_ids.forEach((userId) => {
                if (
                    !assignedUsers
                        .map((assignedUser) => assignedUser.id)
                        .includes(userId)
                ) {
                    assignedUsersList.push(userId);
                }
            });

            // assignedUsers.forEach((assignedUser) => {
            //     if (!values.user_ids.includes(assignedUser.id)) {
            //         unassignedUsersList.push(assignedUser.id);
            //     }
            // });

            if (assignToAll && !filterTags.length) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_all_users: true,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            if (assignToAll && filterTags && filterTags.length > 0) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_users_by_classification_tag_ids: filterTags,
                            assigned_user_ids: [],
                            unassigned_user_ids: unassignedUserIds,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            if (assignToAll && !filterTags.length) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_all_users: true,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            if (assignToAll && filterTags && filterTags.length > 0) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_users_by_classification_tag_ids: filterTags,
                            assigned_user_ids: [],
                            unassigned_user_ids: unassignedUserIds,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            if (assignToAll && !filterTags.length) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_all_users: true,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            if (assignToAll && filterTags && filterTags.length > 0) {
                dispatch(
                    updateOrganizationSmartTipThunk(
                        getAccessTokenSilently,
                        logout,
                        smartTipId,
                        {
                            assign_users_by_classification_tag_ids: filterTags,
                            assigned_user_ids: [],
                            unassigned_user_ids: unassignedUserIds,
                        },
                        resetSmartTips
                    )
                );
                toggleModal();
                return;
            }

            dispatch(
                updateOrganizationSmartTipThunk(
                    getAccessTokenSilently,
                    logout,
                    smartTipId,
                    {
                        assigned_user_ids: values.user_ids || [],
                        unassigned_user_ids: unassignedUserIds,
                    },
                    resetSmartTips
                )
            );
        }

        if (values.team_ids) {
            const assignedTeamsList = [];
            const unassignedTeamsList = [];

            values.team_ids.forEach((teamId) => {
                if (
                    !assignedTeams
                        .map((assignedTeam) => assignedTeam.id)
                        .includes(teamId)
                ) {
                    assignedTeamsList.push(teamId);
                }
            });

            assignedTeams.forEach((assignedTeam) => {
                if (!values.team_ids.includes(assignedTeam.id)) {
                    unassignedTeamsList.push(assignedTeam.id);
                }
            });

            dispatch(
                updateOrganizationSmartTipThunk(
                    getAccessTokenSilently,
                    logout,
                    smartTipId,
                    {
                        assigned_team_ids: assignedTeamsList,
                        unassigned_user_ids: unassignedUserIds,
                    },
                    resetSmartTips
                )
            );
        }
        toggleModal();
    };

    const toggleAssignToAll = () => {
        setAssignToAll(!assignToAll);
        dispatch(assignToAllUsersAction(!assignToAll));
    };

    return (
        // eslint-disable-next-line react/jsx-indent
        <AssignSmartTipModalComponent
            smartTipId={smartTipId}
            toggleModal={() => {
                toggleModal();
                dispatch(resetOrganizationSmartTipDetailsThunk());
            }}
            onUpdate={onUpdate}
            handleAssign={handleAssign}
            assignToAll={assignToAll}
            toggleAssignToAll={toggleAssignToAll}
            isUsersSection={isUsersSection}
            toggleSection={toggleSection}
            setAssignToAll={setAssignToAll}
            unassignedUserIds={unassignedUserIds}
            setUnassignedUserIds={setUnassignedUserIds}
        />
    );
};

AssignSmartTipModal.propTypes = {
    smartTipId: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    resetSmartTips: PropTypes.func.isRequired,
};

AssignSmartTipModal.defaultProps = {};

export default AssignSmartTipModal;
