import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import InfiniteScroll from 'react-infinite-scroller';
import { getUniqueListBy } from 'utilites/uniqueListBy';
import Spinner from 'components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadMoreOrganizationAssignSmartTipAssignedUsersThunk,
    establishOrganizationAssignSmartTipAssignedUsersThunk,
    resetUsersAndTeamsListsThunk,
} from 'redux/actions/thunks/organizationAssignSmartTip';
import useOffset from 'hooks/useOffset';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Wrapper,
    Dropdown,
    SelectedItemsWrapper,
} from './DropdownSelect.style';
import SelectedItem from './SelectedItem';
import DropdownItem from './DropdownItem';

const INFINITE_SCROLL_THRESHOLD = 50;

const DropdownSelect = ({
    type,
    smartTipId,
    items,
    loadMore,
    hasMore,
    onSearch,
    resetItemsList,
    handleSetSelectedItems,
    placeholder,
    disabled,
    preselectedItems,
    fullItem,
    assignToAllUsers,
    uncheckAssignToAllUsers,
    isLoadingOrganizationUsers,
    setSelectedUser,
    loadMoreUsers,
    allUsersLoaded,
    isLoadingUser,
    unassignedUserIds,
    setUnassignedUserIds,
}) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(undefined);
    const [filter, setFilter] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    // const [preselectedItems, setPreselectedItems] = useState([]);
    const { getAccessTokenSilently, logout } = useAuth0();
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    // const [offset, incrementOffset, resetOffset] = useOffset(
    //     INFINITE_SCROLL_THRESHOLD
    // );

    const toggleItem = (item) => {
        if (
            selectedItems.includes(item) ||
            items
                .find((i) => i.id === item.id)
                .assigned_smart_tip_ids.includes(smartTipId)
        ) {
            const foundItem = selectedItems.filter(
                (selectedItem) => selectedItem.id !== item.id
            );
            setSelectedItems(foundItem);
            setUnassignedUserIds([...unassignedUserIds, item.id]);
            disabledAssignToAllCheckbox(item.id, 'unselect');
            uncheckAssignToAllUsers();
        } else {
            disabledAssignToAllCheckbox(item.id, 'select');
            setSelectedItems([item, ...selectedItems]);
        }
    };

    const deselectItem = (id) => {
        const foundItem = selectedItems.filter(
            (selectedItem) => selectedItem.id !== id
        );
        setSelectedItems(foundItem);
        setUnassignedUserIds([...unassignedUserIds, id]);
        disabledAssignToAllCheckbox(id, 'unselect');
        uncheckAssignToAllUsers();
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            return;
        }
        setIsDropdownVisible(false);
    };

    const uniqAssignUsersList = () => {
        const arr = [...preselectedItems, ...items];
        const uniqArr = getUniqueListBy(arr, 'id');
        return uniqArr;
    };
    useEffect(() => {
        // if (assignToAllUsers) {
        //     setSelectedItems(uniqAssignUsersList());
        // } else
        if (!assignToAllUsers && items.length === selectedItems.length) {
            setSelectedItems([...preselectedItems]);
        }
    }, [items, assignToAllUsers]);

    useEffect(() => {
        onSearch(filter);
        if (filter.length > 0) {
            setIsDropdownVisible(true);
        }

        return () => {
            resetItemsList();
        };
    }, [filter]);

    useEffect(() => {
        if (handleSetSelectedItems && fullItem) {
            handleSetSelectedItems(selectedItems);
        } else if (handleSetSelectedItems) {
            const map = selectedItems.map((selectedItem) => selectedItem.id);
            handleSetSelectedItems(map);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    useEffect(() => {
        setSelectedItems(preselectedItems);
    }, [preselectedItems]);

    // eslint-disable-next-line consistent-return
    const disabledAssignToAllCheckbox = (itemId, action) => {
        const isExists = preselectedItems.find(
            (preselectedItem) => preselectedItem.id === itemId
        );
        if (action === 'select' && !isExists) {
            return setSelectedUser(true);
        }
        if (action === 'unselect' && !isExists) {
            return setSelectedUser(false);
        }
    };

    const selected = [];
    const handleSelectedItems = () => {
        items.forEach((item) => {
            if (item.assigned_smart_tip_ids.includes(smartTipId)) {
                if (
                    !selectedItems
                        .map((selectedItem) => selectedItem.id)
                        .includes(item.id)
                ) {
                    selected.push(item);
                }
            }
        });
        setSelectedItems((prev) => [...prev, ...selected]);
    };

    useEffect(() => {
        handleSelectedItems();
    }, [items]);

    return (
        <Wrapper disabled={disabled} ref={dropdownRef}>
            <Input
                placeholder={placeholder}
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                defaultValue={filter}
                disabled={disabled}
                onChange={({ target }) => setFilter(target.value)}
            />

            {isDropdownVisible && (
                <Dropdown>
                    <InfiniteScroll
                        pageStart={0}
                        initialLoad={false}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        useWindow={false}
                        loader={
                            isLoadingOrganizationUsers && (
                                <Spinner key="spinner" small />
                            )
                        }
                    >
                        {items.map((item) => (
                            <DropdownItem
                                key={item.id}
                                type={type}
                                data={item}
                                isSelected={selectedItems
                                    .map((selectedItem) => selectedItem.id)
                                    .includes(item.id)}
                                select={() => toggleItem(item)}
                            />
                        ))}
                    </InfiniteScroll>
                </Dropdown>
            )}

            <SelectedItemsWrapper disabled={disabled}>
                <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={loadMoreUsers}
                    hasMore={allUsersLoaded}
                    useWindow={false}
                    loader={isLoadingUser && <Spinner key="spinner" small />}
                >
                    {selectedItems.map((item) => (
                        <SelectedItem
                            disabled={disabled}
                            key={item.id}
                            type={type}
                            data={item}
                            deselectItem={() => deselectItem(item.id)}
                        />
                    ))}
                </InfiniteScroll>
            </SelectedItemsWrapper>
        </Wrapper>
    );
};

DropdownSelect.propTypes = {
    type: PropTypes.string.isRequired,
    smartTipId: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    resetItemsList: PropTypes.func.isRequired,
    preselectedItems: PropTypes.arrayOf(PropTypes.any),
    handleSetSelectedItems: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fullItem: PropTypes.bool,
    uncheckAssignToAllUsers: PropTypes.func.isRequired,
    assignToAllUsers: PropTypes.bool,
    isLoadingOrganizationUsers: PropTypes.bool,
    setSelectedUser: PropTypes.func,
    loadMoreUsers: PropTypes.func.isRequired,
    allUsersLoaded: PropTypes.bool.isRequired,
    isLoadingUser: PropTypes.bool.isRequired,
    unassignedUserIds: PropTypes.array,
    setUnassignedUserIds: PropTypes.func,
};

DropdownSelect.defaultProps = {
    preselectedItems: [],
    smartTipId: undefined,
    handleSetSelectedItems: undefined,
    placeholder: 'Search',
    disabled: false,
    fullItem: false,
    assignToAllUsers: false,
    isLoadingOrganizationUsers: true,
    setSelectedUser: undefined,
    unassignedUserIds: [],
    setUnassignedUserIds: undefined,
};

export default DropdownSelect;
