import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as HeartIcon } from 'assets/svg/heart-filled.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as CopyIcon } from 'assets/svg/copy-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/svg/pencil-2.svg';
import Button from 'components/Button';
import { ROUTES, MPA_ROUTES } from 'routing/constants';
import {
    CustomerWrapper,
    HeartIconWrapper,
    MiddleSectionWrapper,
    Name,
    Count,
    ButtonsWrapper,
    IconWrapper,
    Divider,
    StyledAvatarPlaceholder,
    Avatar,
} from './SingleCustomer.style';

const SingleCustomer = ({ customer }) => {
    const history = useHistory();

    const goToCustomerDashboard = () => {
        window.localStorage.setItem('MP_ORGANIZATION', customer.slug);
        history.push(ROUTES.dashboard);
    };

    const goToCustomerDetails = () => {
        history.push(`${MPA_ROUTES.customers}/${customer.id}`);
    };

    return (
      <CustomerWrapper>
        <HeartIconWrapper>
          {customer.avatar_url ? (
            <Avatar
                        src={customer.avatar_url}
                        alt={customer.name}
                        onClick={goToCustomerDetails}
                    />
                ) : (
                  <StyledAvatarPlaceholder
                        onClick={goToCustomerDetails}
                        color={
                            customer.background_color ||
                            theme.color.primaryLight
                        }
                    >
                    <HeartIcon
                            fill={customer.color || theme.color.primary}
                        />
                  </StyledAvatarPlaceholder>
                )}
        </HeartIconWrapper>
        <MiddleSectionWrapper>
          <Name onClick={goToCustomerDetails}>{customer.name}</Name>
          <Count>
            <span onClick={goToCustomerDetails}>
              {`${customer.users_count} users`}
            </span>
            {' '}
            <span
                        onClick={() => {
                            const str = `https://learn.medpower.com/login?organization=${customer.slug}`;
                            const el = document.createElement('textarea');
                            el.value = str;
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand('copy');
                            document.body.removeChild(el);
                        }}
                        className="loginLink"
                    >
              · learn.medpower.com/login?organization=
              {customer.slug}
              <CopyIcon
                            fill={theme.color.textBase}
                            style={{ marginLeft: '4px', opacity: 0.6 }}
                        />
            </span>
          </Count>
        </MiddleSectionWrapper>
        <ButtonsWrapper>
          <div className="show-on-hover">
            <IconWrapper onClick={goToCustomerDetails}>
              <EyeIcon fill={theme.color.textLight} />
            </IconWrapper>
            <Divider />
            <IconWrapper
                        onClick={() =>
                            history.push(
                                `${MPA_ROUTES.editCustomer}/${customer.id}`
                            )}
                    >
              <PencilIcon fill={theme.color.textLight} />
            </IconWrapper>
            <Divider />
          </div>
          <Button
                    primary
                    customStyles={{ width: '90px' }}
                    onClick={goToCustomerDashboard}
                >
            Select
          </Button>
        </ButtonsWrapper>
      </CustomerWrapper>
    );
};

SingleCustomer.propTypes = {
    customer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SingleCustomer;
