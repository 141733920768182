import React, { useState } from 'react';
import Input from 'components/Input';
import PropTypes from 'prop-types';
import theme from 'assets/theme/mainTheme';
import { ReactComponent as LightBulbIcon } from 'assets/svg/light-bulb-shine.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close-modal-2.svg';
import {
    DropdownOption,
    DropdownOptionTexts,
    SelectedTip,
    SelectedTipHolder,
    AssignModalBodyHolder,
} from './AssignModalBody.style';

const generateDropdownOptions = (options) =>
    options
        .filter((smartTip) => !smartTip.is_assigned)
        .map((smartTip) => ({
            id: smartTip.id,
            label() {
                return (
                  <DropdownOption>
                    <DropdownOptionTexts>
                      <span>{smartTip.name}</span>
                    </DropdownOptionTexts>
                  </DropdownOption>
                );
            },
            selected: smartTip.selected,
        }));

const AddedSmartTip = ({ name, id, removeSmartTip = () => {} }) => (
  <SelectedTip>
    <LightBulbIcon fill={theme.color.primary} /> 
    {' '}
    <span>{name}</span>
    {' '}
    <CloseIcon className="removeIcon" onClick={() => removeSmartTip(id)} />
  </SelectedTip>
);

AddedSmartTip.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    removeSmartTip: PropTypes.func.isRequired,
};

const AssignModalBody = ({
    assignType,
    handleSearch,
    smartTipsSearchList,
    areUserSmartTipsFetching,
    selectedSmartTips,
    handleSmartTipSelect,
    setSmartTipsSearchList,
    removeSmartTip,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
      <AssignModalBodyHolder>
        <Input
                variant="searchDropdownSelectApi"
                placeholder={`Search for ${assignType}`}
                onClick={() => setDropdownVisible(!dropdownVisible)}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearch(e.target.value);
                }}
                value={searchValue}
                dropdownVisible={dropdownVisible}
                selectOptions={generateDropdownOptions(smartTipsSearchList)}
                handleSelect={handleSmartTipSelect}
                selectButtonLabel="Add"
                handleDropdownSelectSubmit={() => {
                    setSearchValue('');
                    setDropdownVisible(false);
                    setSmartTipsSearchList([]);
                }}
                handleDropdownSelectCancel={() => setDropdownVisible(false)}
                isLoadingOptions={areUserSmartTipsFetching}
            />
        <SelectedTipHolder>
          {selectedSmartTips.map((st) => (
            <AddedSmartTip
                        key={st.id}
                        {...st}
                        removeSmartTip={removeSmartTip}
                    />
                ))}
        </SelectedTipHolder>
      </AssignModalBodyHolder>
    );
};

AssignModalBody.propTypes = {
    assignType: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleSmartTipSelect: PropTypes.func.isRequired,
    setSmartTipsSearchList: PropTypes.func.isRequired,
    removeSmartTip: PropTypes.func.isRequired,
    smartTipsSearchList: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedSmartTips: PropTypes.arrayOf(PropTypes.any).isRequired,
    areUserSmartTipsFetching: PropTypes.bool.isRequired,
};

export default AssignModalBody;
